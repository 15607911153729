export const PERCENTAGE_OF_COMPENSATION = "percentage";
export const FLAT_FEE = "flat_fee";
export const PERCENTAGE_RANGE = "percentage_range";

export const feeTypeOptions = [
  {
    label: "Percentage of Compensation",
    value: PERCENTAGE_OF_COMPENSATION,
  },
  {
    label: "Flat Fee",
    value: FLAT_FEE,
  },
];
