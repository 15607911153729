import { round } from "lodash";
import filter from "lodash/filter";
import isEmpty from "lodash/isEmpty";
import map from "lodash/map";
import moment from "moment";
import React from "react";
import { Col, Row } from "react-grid-system";

import PageCardRowHeader from "../../../../General/PageCardRowHeader";

import FieldSection, { Field } from "./FieldSection";

function EmptyScreeningQuestions() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <p className="body-default">
        Ask screening question on your JobCasts to learn more about your
        candidates. The responses will appear here.
      </p>
    </div>
  );
}

function QuestionsAndResponses({ questionsAndResponses, employer }) {
  if (isEmpty(questionsAndResponses) && employer) {
    return <EmptyScreeningQuestions />;
  }

  return map(questionsAndResponses, (sq) => (
    <Row>
      <Col xs={12}>
        <Field label={sq.question}>{sq.answer}</Field>
      </Col>
    </Row>
  ));
}

function CandidateLocations({ locations }) {
  if (isEmpty(filter(locations, (location) => location.city && location.state))) { return null; }

  return (
    <div
      style={{
        marginBottom: 20,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div className="field-label" style={{ paddingBottom: 0 }}>
        Would like to be considered for the following location(s):
      </div>
      {map(locations, (location) => (
        <div>
          {location.city}
          ,
          {' '}
          {location.state}
          <br />
        </div>
      ))}
    </div>
  );
}

function CandidateScreeningQuestions({ candidate, employer }) {
  const {
    hourlyBillRate,
    hourlyPayRate,
    permConversionDesired,
    permConversionSalaryExpectations,
    availableStartDate,
    plannedTimeOffDescription,
    visaSponsorship,
    screeningQuestionsAndResponses,
    usesPayRateMarkup,
    markupPercentage,
  } = candidate.attributes;

  const notYetAnswered = permConversionDesired === null;

  return (
    <>
      <PageCardRowHeader title="Candidate Responses to Screening Questions" />
      <FieldSection>
        <Row>
          <Col xs={3}>
            <Field label="Std. Bill Rate">{`$${hourlyBillRate}/hr`}</Field>
          </Col>
          <Col xs={3}>
            <Field label="Std. Pay Rate">
              {hourlyPayRate
                ? `$${hourlyPayRate}/hr`
                : "Not Provided by Agency"}
            </Field>
          </Col>
          {usesPayRateMarkup && (
            <Col xs={3}>
              <Field label="Markup Percentage">
                {`${round(markupPercentage * 100, 2)}%`}
              </Field>
            </Col>
          )}
        </Row>
        <Row>
          <Col xs={3}>
            <Field label="Availability Begins">
              {moment(availableStartDate).format("MMM Do, YYYY")}
            </Field>
          </Col>
          <Col xs={3}>
            <Field label="Planned Time Off">
              {notYetAnswered ? "To be provided by candidate" : plannedTimeOffDescription || "None"}
            </Field>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <Field label="Conversion Salary Expectations">
              {(() => {
                if (notYetAnswered) {
                  return "To be provided by candidate";
                } if (permConversionDesired) {
                  return permConversionSalaryExpectations;
                }
                return "Not interested in full time";
              })()}
            </Field>
          </Col>
          <Col xs={6}>
            <Field label="Conversion Visa Sponsorship">
              {(() => {
                if (notYetAnswered) {
                  return "To be provided by candidate";
                } if (permConversionDesired) {
                  return (visaSponsorship ? "Yes" : "No");
                }
                return "None";
              })()}
            </Field>
          </Col>
        </Row>
        <QuestionsAndResponses
          questionsAndResponses={screeningQuestionsAndResponses}
          employer={employer}
        />
        <CandidateLocations
          locations={candidate.attributes.positionLocations}
        />
      </FieldSection>
    </>
  );
}

export default CandidateScreeningQuestions;
