import { useFormikContext } from "formik";
import some from "lodash/some";
import zip from "lodash/zip";
import React, { useMemo } from "react";

import { useSelector } from "react-redux";

import { getCurrentIndividual } from "../../../../selectors/individualSelectors";
import ActionModalFooter from "../../../General/ActionModal/ActionModalFooter";

import { allChecklistItemsComplete, detectChecklistItemChange } from "./fieldHelpers";

const submitValuesChanged = (currValues, initialValues) => {
  return some(zip(currValues.checklistItems, initialValues.checklistItems), ([currItem, initialItem]) => {
    return detectChecklistItemChange(initialItem, currItem);
  });
};

function FormFooter({ initialValues }) {
  const currentIndividual = useSelector(getCurrentIndividual);
  const { values, isSubmitting, handleSubmit } = useFormikContext();

  const isDisabled = useMemo(
    () => !submitValuesChanged(values, initialValues),
    [initialValues, values]
  );

  const isCompleteOnSubmit = useMemo(
    () => allChecklistItemsComplete(values.checklistItems, currentIndividual),
    [values, currentIndividual]
  );

  return (
    <ActionModalFooter
      actionText={isCompleteOnSubmit ? "Complete" : "Update"}
      handleAction={handleSubmit}
      isSubmitting={isSubmitting}
      disabled={isDisabled}
    />
  );
}

export default FormFooter;
