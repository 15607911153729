import {
  makeStyles,
  TableCell,
  TableRow,
} from "@material-ui/core";

import classNames from "classnames";
import moment from "moment";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { openProfileDrawer } from "../../../../../../actions/shared/uiActions";
import GlobalAvatar from "../../../../../General/GlobalAvatar";
import PopoverLink from "../../../../../General/PopoverLink";
import { formatter } from "../../../../../recruiter/Candidates/actions/modals/utils";
import { NonStandardHours, StandardHours } from "../../../../../shared/PayPeriods/tableColumns";
import { CONTRACT_WORK } from "../../../../JobCasts/view/Candidates/constants";
import CandidateDrawer from "../../../../JobCasts/view/Candidates/view/CandidateDrawer";

const useStyles = makeStyles(() => ({
  standardCell: {
    color: "#37474F",
  },
  alertCell: {
    color: "#F44336",
  },
  boldedCell: {
    fontWeight: "bold",
    color: "#37474F",
  },
  linkText: {
    color: "#008DAE",
    "&:hover": {
      textDecoration: "underline",
      color: "#B0BEC5",
      cursor: "pointer",
    },
  },
  popoverTable: {
    "& td": {
      paddingTop: "8px !important",
      paddingBottom: "8px !important",
      paddingLeft: "16px !important",
      paddingRight: "16px !important"
    },
    "& tr td:first-child": {
      borderLeft: "none !important",
    },
    "& tr td:last-child": {
      borderRight: "none !important",
    },
    "& tr:last-child td": {
      borderBottom: "none !important",
    },
  }
}));

function WorkerCellContent({ attributes }) {
  const dispatch = useDispatch();
  const contract = useSelector(state => state.contracts.items[attributes.contractId]);

  const openCandidateDrawer = () => {
    dispatch(
      openProfileDrawer(
        <CandidateDrawer
          candidateId={contract.attributes.recruiterSubmissionId}
          navSelection={CONTRACT_WORK}
        />
      )
    );
  };

  const { avatarIcon, firstName, lastName } = contract.attributes;

  return (
    <div style={{
      display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: 8
    }}
    >
      <GlobalAvatar
        individual={{
          avatarIcon,
          firstName,
          lastName,
        }}
      />
      <div
        style={{
          marginLeft: 12,
          cursor: 'pointer',
          textDecoration: 'underline'
        }}
        role="button"
        onClick={openCandidateDrawer}
      >
        {contract.attributes.candidateName}
      </div>
    </div>
  );
}

export function BilledAmountCellContent({
  adjustedEmployerCollectionAmount,
  additionalBilling,
  appliedDomesticTax,
  employerCollectionTaxAdditionsAmount,
  standardHours,
  nonStandardHours,
  doubleTimeHours,
  hourlyBillRate,
}) {
  const { state, zipCode, estimatedCombinedRate } = appliedDomesticTax ?? {};

  const classes = useStyles();

  const renderLink = (handleClick) => (
    <span
      className={classNames(classes.linkText, "bold")}
      onMouseEnter={handleClick}
      onMouseLeave={handleClick}
    >
      {formatter.format(adjustedEmployerCollectionAmount)}
    </span>
  );

  const renderPopoverContent = () => (
    <table className={classNames("rounded", classes.popoverTable)}>
      <tbody>
        <tr>
          <td className="semibold">
            Std. Hours Amount
          </td>
          <td>
            {formatter.format(standardHours * hourlyBillRate)}
          </td>
        </tr>
        {(nonStandardHours > 0 || doubleTimeHours > 0) && (
          <tr>
            <td className="semibold">
              Non-Std. Hours Amount
            </td>
            <td>
              {formatter.format(
                ((parseFloat(nonStandardHours || 0.0)) * hourlyBillRate * 1.5) +
                ((parseFloat(doubleTimeHours || 0.0)) * hourlyBillRate * 2)
              )}
            </td>
          </tr>
        )}
        {additionalBilling && additionalBilling > 0 && (
          <tr>
            <td className="semibold">
              Additional Billing
            </td>
            <td>{formatter.format(additionalBilling || 0)}</td>
          </tr>
        )}
        {appliedDomesticTax && (
          <tr>
            <td className="semibold">
              {(estimatedCombinedRate * 100).toFixed(2)}% {state} - {zipCode} Sales Tax
            </td>
            <td>
              {formatter.format(employerCollectionTaxAdditionsAmount || 0)}
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );

  if (additionalBilling > 0 || appliedDomesticTax) {
    return (
      <PopoverLink
        renderLink={renderLink}
        renderPopoverContent={renderPopoverContent}
      />
    );
  }

  return (
    <span className="bold">
      {formatter.format(adjustedEmployerCollectionAmount)}
    </span>
  );
}

function PayPeriodsTableRow({ attributes, automatedTimesheetsSubmission }) {
  const classes = useStyles();

  return (
    <TableRow key={attributes.id}>
      <TableCell align="center">
        <WorkerCellContent attributes={attributes} />
      </TableCell>
      <TableCell classes={{ root: classes.standardCell }} align="center">
        {moment(attributes.startDate).format("MMM Do, YYYY")} -{" "}
        {moment(attributes.endDate).format("MMM Do, YYYY")}
      </TableCell>
      <TableCell align="center" classes={{ root: classes.standardCell }}>
        <StandardHours {...attributes} />
      </TableCell>
      {!automatedTimesheetsSubmission && (
        <TableCell align="center" classes={{ root: classes.standardCell }}>
          <NonStandardHours {...attributes} />
        </TableCell>)
      }
      <TableCell align="center" classes={{ root: classes.boldedCell }}>
        <BilledAmountCellContent {...attributes} />
      </TableCell>
      <TableCell align="center" classes={{ root: classes.standardCell }}>
        {attributes.approverName} on{" "}
        {moment(attributes.approvedAt).format("MMM Do")}
      </TableCell>
    </TableRow>
  );
}

export default PayPeriodsTableRow;
