import { Tooltip } from "@material-ui/core";
import InfoOutlined from "@material-ui/icons/InfoOutlined";
import React from "react";
import { Row, Col } from "react-grid-system";

import { stateOptions } from "../../../../../util/constantsToOptions";
import FormContextDatePicker from "../../../../forms/formik/FormContextInputs/FormContextDatePicker";
import FormContextDropdown from "../../../../forms/formik/FormContextInputs/FormContextDropdown";
import FormContextTextField from "../../../../forms/formik/FormContextInputs/FormContextTextField";
import FormContextWysiwyg from "../../../JobCasts/create/formContextInputs/FormContextWysiwyg";
import SectionHeader from "../../../JobCasts/view/Candidates/actions/modals/Hire/SectionHeader";

import EmployerTempPrerequisitesField from "./EmployerTempPrerequisitiesField";
import FieldSection from "./FieldSection";

function StartDateField() {
  return (
    <FormContextDatePicker id="scheduledStartDate" label="Start Date" />
  );
}

function EndDateField() {
  return (
    <FormContextDatePicker
      id="scheduledEndDate"
      label={(
        <Tooltip
          placement="right"
          title="You will be reminded when this date approaches"
        >
          <div className="static-input-label">
            Tentative End Date
            <InfoOutlined
              style={{
                color: "#B0BEC5",
                fontSize: 13,
                paddingLeft: 4,
              }}
            />
          </div>
        </Tooltip>
      )}
    />
  );
}

function StateField() {
  return (
    <FormContextDropdown
      id="primaryWorkState"
      label="Primary Work State"
      options={stateOptions}
    />
  );
}

function ZipCodeField() {
  return (
    <FormContextTextField
      id="primaryWorkZipCode"
      label="Primary Work Zip"
      inputProps={{ maxLength: 5 }}
    />
  );
}

function StatementOfWorkField() {
  return (
    <FormContextWysiwyg
      id="statementOfWorkDescription"
      label={(
        <Tooltip
          placement="right"
          title="This will populate as part of the Statement of Services to be signed before work can begin."
        >
          <div>
            Description of Work
            <InfoOutlined
              style={{
                color: "#B0BEC5",
                fontSize: 13,
                paddingLeft: 4,
              }}
            />
          </div>
        </Tooltip>
      )}
      maxLength={10000}
    />
  );
}

function WorkInformation() {
  return (
    <>
      <SectionHeader title="Work Information" />
      <FieldSection>
        <Row>
          <Col xs={12} md={4}>
            <StartDateField />
          </Col>
          <Col xs={12} md={4}>
            <EndDateField />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={4}>
            <StateField />
          </Col>
          <Col xs={12} md={4}>
            <ZipCodeField />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <StatementOfWorkField />
          </Col>
        </Row>
      </FieldSection>
      <SectionHeader title="Pre-Assignment Steps" />
      <FieldSection>
        <Row>
          <EmployerTempPrerequisitesField />
        </Row>
      </FieldSection>
    </>
  );
}

export default WorkInformation;
