import { FormControlLabel, makeStyles, Radio } from "@material-ui/core";
import classnames from "classnames";
import { useFormikContext } from "formik";
import { get, map } from "lodash";
import React, { useCallback } from "react";

import RadioGroup from "../../../../forms/custom/RadioGroup";

const RADIO_OPTIONS = [
  {
    value: false,
    label: "The agency will submit bill rates on a per-candidate basis",
  },
  {
    value: true,
    label: "We have previously determined markup percentages based on the candidate’s pay rate"
  },
];

const useStyles = makeStyles(() => ({
  fieldLabel: {
    paddingBottom: 6,
    lineHeight: "13px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
}));

function MarkupFeesToggle() {
  const { setFieldValue, values } = useFormikContext();
  const value = Boolean(get(values, "temp.markupFeesEnabled"));

  const onChange = useCallback(
    (_, newValue) => {
      setFieldValue("temp.markupFeesEnabled", newValue === "true");
    },
    [setFieldValue]
  );

  const classes = useStyles();

  return (
    <>
      <div className={classnames("static-input-label", classes.fieldLabel)}>
        What type of contract agreement is in place?
      </div>
      <RadioGroup
        id="markupFeesEnabled"
        {...{ value, onChange }}
        style={{ padding: "0 0 16px 32px", marginBottom: 0 }}
      >
        {map(RADIO_OPTIONS, ({ value, label }) => (
          <FormControlLabel
            control={<Radio color="primary" />}
            {...{ label, value }}
          />
        ))}
      </RadioGroup>
    </>
  );
}

export default MarkupFeesToggle;
