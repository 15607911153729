import axios from "axios";

const generateHeader = () => {
  const token = global.localStorage.authToken;
  const header = {
    Authentication: token ? `Bearer ${token}` : undefined,
  };

  if (token && global.localStorage.viewingAs) {
    header.ViewingAs = JSON.parse(global.localStorage.viewingAs).id;
  }

  if (token && global.localStorage.ghosting) {
    header.ghosting = global.localStorage.ghosting;
  }

  return header;
};

const handleTokenError = (error) => {
  const { response } = error;
  if (response.status === 401) {
    if (response.data.error === "INVALID_TOKEN") {
      window.dispatchEvent(new Event("token_expired"));
      return;
    }
  }

  throw error; // rethrow the error so it can be caught by the caller
};

export const GET = (url, config = {}) => axios({
  method: "GET",
  headers: generateHeader(),
  url,
  config
}).catch(handleTokenError);

export const POST = (url, data) => axios({
  method: "POST",
  headers: generateHeader(),
  url,
  data,
}).catch(handleTokenError);

export const PATCH = (url, data) => axios({
  method: "PATCH",
  headers: generateHeader(),
  url,
  data,
}).catch(handleTokenError);

export const DELETE = (url) => axios({
  method: "DELETE",
  headers: generateHeader(),
  url,
}).catch(handleTokenError);
