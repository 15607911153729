import includes from "lodash/includes";

import {
  string, object, array, boolean, ref, number
} from "../../../util/yup";
import {
  PERCENTAGE_OF_COMPENSATION, FLAT_FEE,
  PERCENTAGE_RANGE
} from "../../forms/formik/FormikFeeOptions/fieldHelpers";

import { CUSTOM } from "./TempInviteAgency/fieldHelpers";

const buildPlacementTypeSchema = () => {
  const buildFeeOptionSchema = (isMultiple = false) =>
    object().shape({
      fee_type: string(),
      fee: string().when("fee_type", {
        is: PERCENTAGE_OF_COMPENSATION,
        then: string()
          .validFeePercentage()
          .required("Cannot be blank")
          .test(
            "minValPercent",
            "Must be greater than 1%",
            (val) => parseFloat(val) > 1
          )
          .test(
            "maxValPercent",
            "Must be less than 100%",
            (val) => parseFloat(val) < 100
          ),
        otherwise: string()
          .required("Cannot be blank")
          .test(
            "minVal",
            "Must be greater than 1%",
            (val) => parseFloat(val?.replace(",", "")) > 1
          ),
      }),
      currency: string().when("fee_type", {
        is: FLAT_FEE,
        then: string().required("Cannot be blank").nullable(),
        otherwise: string().nullable(),
      }),
      ...(isMultiple ? { fee_note: string().required("Cannot be blank") } : {}),
    });

  return object().shape({
    feeType: string().required("Cannot be blank"),
    positionAssignments: array().of(string()),
    feeOptions: array().when("feeType", {
      is: CUSTOM,
      then: array().when("multipleFees", {
        is: true,
        then: array().of(buildFeeOptionSchema(true)),
        otherwise: array().of(buildFeeOptionSchema(false)),
      }),
    }),
  });
};

const buildContractTypeSchema = () => {
  const buildFeeOptionSchema = (isMultiple = false) =>
    object().shape({
      fee_type: string(),
      fee: string().when("fee_type", {
        is: type => includes([PERCENTAGE_RANGE, PERCENTAGE_OF_COMPENSATION], type),
        then: string()
          .validFeePercentage()
          .required("Cannot be blank")
          .test(
            "minValPercent",
            (val) => parseFloat(val) >= 0
          )
          .test(
            "maxValPercent",
            "Must be less than 100%",
            (val) => parseFloat(val) < 100
          ),
        otherwise: string()
          .required("Cannot be blank")
          .test(
            "minVal",
            "Must be 0 or greater",
            (val) => parseFloat(val?.replace(",", "")) >= 0
          ),
      }),
      currency: string().when("fee_type", {
        is: FLAT_FEE,
        then: string().required("Cannot be blank").nullable(),
        otherwise: string().nullable(),
      }),
      fee_max: string().when("fee_type", {
        is: PERCENTAGE_RANGE,
        then: string()
          .nullable()
          .required("Cannot be blank")
          .greaterThanOther(ref("fee"), "Must be greater than the minimum value")
          .test(
            "maxValPercent",
            "Must be less than 100%",
            (val) => parseFloat(val) < 100
          ),
        otherwise: string().nullable(),
      }),
      ...(isMultiple
        ? { fee_note: string().required("Cannot be blank") }
        : {}),
    });

  return object().shape({
    feeType: string().required("Cannot be blank"),
    positionAssignments: array().of(string()),
    feeOptions: array().when("feeType", {
      is: CUSTOM,
      then: array().when("multipleFees", {
        is: true,
        then: array().of(buildFeeOptionSchema(true)),
        otherwise: array().of(buildFeeOptionSchema(false)),
      }),
    }),
    markupFeesEnabled: boolean(),
    markupFeeOptions: array().when("markupFeesEnabled", {
      is: true,
      then: array().when("markupMultipleFees", {
        is: true,
        then: array().of(buildFeeOptionSchema(true)),
        otherwise: array().of(buildFeeOptionSchema(false)),
      }),
      otherwise: array().nullable(),
    }),
  });
};

export const buildValidationSchema = ({ currentOrganization, agencyInvite = false }) => {
  const agencyInformationSchema = {
    agency: string().nullable().required("Cannot be blank"),
    email: string()
      .nullable()
      .lowercase()
      .required("Cannot be blank")
      .email("Improper format, example: example@email.com")
      .uniqueEmail(
        ref("existingEmails"),
        "This agency has been invited and must accept their invitation"
      ),
    firstName: string().nullable().required("Cannot be blank"),
    lastName: string().nullable().required("Cannot be blank"),
    employerAbsorptionRate: number().nullable().required("Cannot be blank"),
  };

  const placementTypeSchema = {
    placementTypes: object()
      .shape({
        perm: boolean(),
        temp: boolean(),
      })
      .test(
        "thing",
        "Must select at least one placement type",
        (value) => value.perm || value.temp
      ),
  };

  return object().shape({
    ...(agencyInvite && { ...agencyInformationSchema }),
    ...placementTypeSchema,
    perm: object().when("placementTypes.perm", {
      is: true,
      then: buildPlacementTypeSchema(),
      otherwise: object().nullable(),
    }),
    ...(currentOrganization.contractActivated && {
      temp: object().when("placementTypes.temp", {
        is: true,
        then: buildContractTypeSchema(),
        otherwise: object().nullable(),
      }),
    }),
  });
};
