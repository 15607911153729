import { isEmpty, map } from "lodash";
import React from "react";

import useSharedStyles from "../../employer/Agencies/ActiveAgencies/ActiveAgenciesTable/useSharedStyles";
import GlobalAvatar from "../../General/GlobalAvatar";
import PopoverLink from "../../General/PopoverLink";
import { formatter } from "../../recruiter/Candidates/actions/modals/utils";
import { RecruiterSubmissionName } from "../Contracts/tableUtils";

const popoverTable = (
  { standardHoursDetails, nonStandardHoursDetails, doubleTimeHoursDetails }
) => {
  const headers = ['Mon', 'Tue', 'Wed', 'Thu', "Fri"];
  const standardRow = [...(standardHoursDetails || [])];
  const nonStandardRow = [...(nonStandardHoursDetails || [])];
  const doubleTimeRow = [...(doubleTimeHoursDetails || [])];

  // Don't show weekend unless it has some values
  let hasWeekend = false;
  hasWeekend ||= Number(standardRow[5] ?? 0) > 0 || Number(standardRow[6] ?? 0) > 0;
  hasWeekend ||= Number(nonStandardRow[5] ?? 0) > 0 || Number(nonStandardRow[6] ?? 0) > 0;
  hasWeekend ||= Number(doubleTimeRow[5] ?? 0) > 0 || Number(doubleTimeRow[6] ?? 0) > 0;

  if (hasWeekend) {
    headers.push('Sat', 'Sun');
  } else {
    standardRow.splice(5);
    nonStandardRow.splice(5);
    doubleTimeRow.splice(5);
  }

  return (
    <table className="rounded" style={{ textAlign: "center" }} data-cy="timesheet-hours-popover">
      <tbody>
        <tr>
          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
          <th />
          {headers.map(day => (<th key={day}>{day}</th>))}
        </tr>
        <tr>
          <td style={{ width: "48px", padding: "8px", fontWeight: "bold" }}>
            Std.
          </td>
          {standardRow.map(hours => (<td key={hours} style={{ width: "48px", padding: "8px" }}>{hours}</td>))}
        </tr>
        {nonStandardRow.length > 0 && (
        <tr>
          <td style={{ width: "48px", padding: "8px", fontWeight: "bold" }}>
            1.5x
          </td>
          {nonStandardRow.map(hours => (<td key={hours} style={{ width: "48px", padding: "8px" }}>{hours}</td>))}
        </tr>
        )}
        {doubleTimeRow.length > 0 && (
        <tr>
          <td style={{ width: "48px", padding: "8px", fontWeight: "bold" }}>
            2x
          </td>
          {doubleTimeRow.map(hours => (<td key={hours} style={{ width: "48px", padding: "8px" }}>{hours}</td>))}
        </tr>
        )}
      </tbody>
    </table>
  );
};

function CustomPayRates({ dataItems }) {
  return (
    <table className="rounded" style={{ textAlign: "center" }}>
      <tbody>
        <tr>
          <th>Pay Rule</th>
          <th>Hours</th>
          <th>Wages</th>
        </tr>
        {map(dataItems, ({ name, hours, wages }) => (
          <tr key={name}>
            <td style={{ padding: "8px" }}>{name}</td>
            <td style={{ padding: "8px" }}>{parseFloat(hours).toFixed(2)}</td>
            <td style={{ padding: "8px" }}>{parseFloat(wages).toFixed(2)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export const StandardHours = (props) => {
  const sharedClasses = useSharedStyles();
  const { standardHours, standardHoursDetails, customPayRatesData } = props;

  if (parseFloat(standardHours || 0) <= 0) {
    return (<span>0</span>);
  }

  if (!standardHoursDetails && isEmpty(customPayRatesData)) {
    return (<span>{parseFloat(standardHours || 0)}</span>);
  }

  const cellContent = (handleClick) => {
    return (
      <span
        className={sharedClasses.linkText}
        onMouseEnter={handleClick}
        onMouseLeave={handleClick}
      >
        {parseFloat(standardHours || 0.0)}
      </span>
    );
  };
  const renderPopoverContent =
    isEmpty(customPayRatesData) ?
      () => popoverTable(props) :
      () => <CustomPayRates dataItems={customPayRatesData} />;

  return (
    <PopoverLink
      renderLink={cellContent}
      renderPopoverContent={renderPopoverContent}
    />
  );
};

export const NonStandardHours = (props) => {
  const sharedClasses = useSharedStyles();
  const {
    nonStandardHours, doubleTimeHours, nonStandardHoursDetails, doubleTimeHoursDetails
  } = props;

  const totalHours = parseFloat(nonStandardHours || 0.0) + parseFloat(doubleTimeHours || 0.0);

  if ((totalHours <= 0)) {
    return (<span>0</span>);
  }

  const renderLink = (handleClick) => (
    <span
      className={`${sharedClasses.linkText}`}
      onMouseEnter={handleClick}
      onMouseLeave={handleClick}
    >
      {totalHours}
    </span>
  );

  const oldPopoverContent = () => (
    <table className="rounded" style={{ textAlign: "center" }}>
      <tbody>
        <tr>
          <th>Rate</th>
          <th>Hours</th>
        </tr>
        <tr>
          <td style={{ width: "48px", padding: "8px" }}>1.5x</td>
          <td style={{ width: "48px", padding: "8px" }}>
            {parseFloat(nonStandardHours || 0.0)}
          </td>
        </tr>
        <tr>
          <td style={{ width: "48px", padding: "8px" }}>2x</td>
          <td style={{ width: "48px", padding: "8px" }}>
            {parseFloat(doubleTimeHours || 0.0)}
          </td>
        </tr>
      </tbody>
    </table>
  );

  return (
    <PopoverLink
      renderLink={renderLink}
      renderPopoverContent={() =>
        (nonStandardHoursDetails || doubleTimeHoursDetails)
          ? popoverTable(props)
          : oldPopoverContent()}
    />
  );
};

export const AdditionalBilling = ({ additionalBilling, additionalBillingReason }) => {
  const sharedClasses = useSharedStyles();

  const renderLink = (handleClick) => (
    <span
      className={`${parseFloat(additionalBilling) > 0 ? sharedClasses.linkText : ""}`}
      onMouseEnter={handleClick}
      onMouseLeave={handleClick}
    >
      {formatter.format(additionalBilling)}
    </span>
  );
  const renderPopoverContent = () => (
    <table
      className="rounded"
      style={{ margin: 8, textAlign: "center" }}
    >
      <tbody>
        <tr style={{ whiteSpace: "pre" }}>
          {additionalBillingReason}
        </tr>
      </tbody>
    </table>
  );

  if (additionalBilling > 0) {
    return (
      <PopoverLink
        renderLink={renderLink}
        renderPopoverContent={renderPopoverContent}
      />
    );
  }

  return (
    <span>
      {formatter.format(additionalBilling)}
    </span>
  );
};

const tableColumns = [
  {
    colKey: "AVATAR",
    label: "",
    renderData: ({ avatarIcon, firstName, lastName }) => (
      <GlobalAvatar
        individual={{
          avatarIcon,
          firstName,
          lastName,
        }}
      />
    ),
    style: { width: "6%" },
  },
  {
    colKey: "NAME",
    label: <span className="overline">Worker</span>,
    renderData: ({ recruiterSubmissionId, employer, recruiter }) => (
      <RecruiterSubmissionName {...{ recruiterSubmissionId, employer, recruiter }} />
    ),
    style: { width: "16%" },
    orderFunc: ({ lastName }) => lastName.toLowerCase(),
  },
  {
    colKey: "TITLE",
    label: <span className="overline">Job</span>,
    renderData: ({ jobTitle }) => (
      <span
        style={{
          whiteSpace: "normal",
          overflowWrap: "break-word",
          paddingRight: "10px"
        }}
      >
        {jobTitle}
      </span>
    ),
    style: { width: "20%" },
    orderFunc: ({ jobTitle }) => jobTitle.toLowerCase(),
  },
  {
    colKey: "STANDARD_HOURS",
    label: <span className="overline">Std. Hours</span>,
    renderData: StandardHours,
    style: { width: "8%" },
    orderFunc: ({ standardHours }) => parseFloat(standardHours || 0),
  },
  {
    colKey: "NON_STANDARD_HOURS",
    label: <span className="overline">Non-Std. Hours</span>,
    renderData: NonStandardHours,
    style: { width: "10%" },
    // eslint-disable-next-line max-len
    orderFunc: ({ nonStandardHours, doubleTimeHours }) => parseFloat(nonStandardHours || 0.0) + parseFloat(doubleTimeHours || 0.0),
  },
  {
    colKey: "ADDITIONAL_BILLING",
    label: <span className="overline">Additions</span>,
    renderData: AdditionalBilling,
    style: { width: "10%" },
    orderFunc: ({ additionalBilling }) => additionalBilling,
  },
  {
    colKey: "WEEKLY_BILL",
    label: <span className="overline">Bill Amount</span>,
    renderData: ({ totalAmount, disputedAt }) => {
      return (
        <span
          className="bold"
          style={disputedAt ? { color: "#FF6D6D" } : {}}
        >
          {disputedAt ? "Disputed By Employer" : totalAmount}
        </span>
      );
    },
    style: { width: "12%" },
    orderFunc: ({ totalAmount }) => totalAmount,
  }
];

export default tableColumns;
