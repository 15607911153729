import filter from 'lodash/filter';
import map from 'lodash/map';
import { useSelector } from 'react-redux';

import { getCurrentOrganization } from "../../../selectors/organizationSelectors";

export const AGENCY_MANAGER = "agency_manager";
export const FINANCE_MANAGER = "finance_manager";
export const JOBCAST_CREATOR = "jobcast_creator";
export const TEAM_MANAGER = "team_manager";
export const STANDARD_EMPLOYER = "standard_employer";
export const TIMESHEET_APPROVER = "timesheet_approver";

const EMPLOYER_ROLE_OPTIONS = [
  { label: "Agency Manager", value: AGENCY_MANAGER },
  { label: "Finance Manager", value: FINANCE_MANAGER },
  { label: "Jobcast Creator", value: JOBCAST_CREATOR },
  { label: "Team Manager", value: TEAM_MANAGER },
  { label: "Timesheet Approver", value: TIMESHEET_APPROVER, contract: true },
  { label: "Standard Employer", value: STANDARD_EMPLOYER, isFixed: true },
];

export const useEmployerRoleOptions = () => {
  const organization = useSelector(getCurrentOrganization);

  const { contractActivated, ukgWorkforceIntegrationEnabled } = organization;

  if (!contractActivated) {
    return filter(EMPLOYER_ROLE_OPTIONS, option => !option.contract);
  } if (ukgWorkforceIntegrationEnabled) {
    return map(EMPLOYER_ROLE_OPTIONS, option => {
      if (option.value === TIMESHEET_APPROVER) {
        return { ...option, label: "Contract Manager" };
      }
      return option;
    });
  }
  return EMPLOYER_ROLE_OPTIONS;
};
