import moment from "moment";
import React from "react";
import { Col, Row } from "react-grid-system";

import { useSelector } from "react-redux";

import { getCurrentOrganization } from "../../../../../../selectors/organizationSelectors";
import { buildFullName } from "../../../../../../util/formatHelpers";
import PageCardRowHeader from "../../../../../General/PageCardRowHeader";
import FieldSection, { Field } from "../../ResponsesTab/FieldSection";

function ContractStatusField({ contract }) {
  const determineStatusText = () => {
    if (!contract.attributes.activatedAt) return "Pending Pre-Assignment";

    if (moment.parseZone(contract.attributes.endedAt).isBefore(moment())) return "Closed";

    return "Open";
  };

  return (
    <Field label="Contract Status">
      {determineStatusText()}
    </Field>
  );
}

function JobTitleField({ jobcast }) {
  return (
    <Field label="Job Title">
      {jobcast.attributes.title}
    </Field>
  );
}

function StartDateField({ contract }) {
  return (
    <Field label="Start Date">
      {moment.parseZone(contract.attributes.scheduledStartDate).format("MMM Do, YYYY")}
    </Field>
  );
}

function EndDateField({ contract }) {
  return (
    <Field label="End Date">
      {contract.attributes.endedAt
        ? moment.parseZone(contract.attributes.endedAt).format("MMM Do, YYYY")
        : "N/A"}
    </Field>
  );
}

function ContractLength({ contract }) {
  const start = moment.parseZone(contract.attributes.scheduledStartDate);
  let length = -1;

  if (contract.attributes.endedAt) {
    if (moment().isAfter(start)) {
      length = moment.parseZone(contract.attributes.endedAt).diff(start, "days");
    }
  } else {
    length = moment().diff(start, "days");
  }

  let content;
  if (length < 0) content = "N/A";
  else if (length <= 1) content = "1 Day";
  else content = `${length} Days`;

  return (
    <Field label="Contract Length">
      {content}
    </Field>
  );
}

function ScheduledEndDate({ contract }) {
  return (
    <Field label="Tentative End Date">
      {moment.parseZone(contract.attributes.scheduledEndDate).format("MMM Do, YYYY")}
    </Field>
  );
}

function PrimaryApproversField({ contract }) {
  const currentOrganization = useSelector(getCurrentOrganization);

  return (
    <Field label={currentOrganization.ukgWorkforceIntegrationEnabled ? "Primary Managers" : "Primary Approvers"}>
      {contract.attributes.primaryApprovers.map((approver) => (
        <div key={approver.id}>
          {approver.name}
        </div>
      ))}
    </Field>
  );
}

function TimesheetSubmittersField({ contract }) {
  return (
    <Field label="Timesheet Submitters">
      {contract.attributes.timesheetSubmitters.map((submitter) => (
        <div key={submitter.id}>
          {buildFullName(submitter.firstName, submitter.lastName)}
        </div>
      ))}
    </Field>
  );
}

function BillRateField({ candidate }) {
  return (
    <Field label="Std. Bill Rate">
      {`$${candidate.attributes.hourlyBillRate.toFixed(2)} / hr`}
    </Field>
  );
}

function PayRateField({ candidate }) {
  return (
    <Field label="Std. Pay Rate">
      {candidate.attributes.hourlyPayRate
        ? `$${candidate.attributes.hourlyPayRate.toFixed(2)} / hr`
        : "Not Provided by Agency"}
    </Field>
  );
}

function MarkupPercentageField({ candidate }) {
  return (
    <Field label="Markup Percentage">
      {`${(candidate.attributes.markupPercentage * 100).toFixed(2)}%`}
    </Field>
  );
}

function StaffingFirmField({ candidate }) {
  return (
    <Field label="Staffing Firm">
      {candidate.attributes.agencyName}
    </Field>
  );
}

function ContractInformation({
  candidate, contract, jobcast, employer
}) {
  return (
    <>
      <PageCardRowHeader title="Contract Information" />
      <FieldSection>
        <Row>
          <Col xs={3}>
            <ContractStatusField contract={contract} />
          </Col>
          <Col xs={9}>
            <JobTitleField jobcast={jobcast} />
          </Col>
        </Row>
        <Row>
          <Col xs={3}>
            <StartDateField contract={contract} />
          </Col>
          <Col xs={3}>
            <EndDateField contract={contract} />
          </Col>
          <Col xs={3}>
            <ContractLength contract={contract} />
          </Col>
          <Col xs={3}>
            <ScheduledEndDate contract={contract} />
          </Col>
        </Row>
        <Row>
          <Col xs={3}>
            <PrimaryApproversField contract={contract} />
          </Col>
          <Col xs={3}>
            <BillRateField candidate={candidate} />
          </Col>
          <Col xs={3}>
            <PayRateField candidate={candidate} />
          </Col>
          {candidate.attributes.usesPayRateMarkup && (
            <Col xs={3}>
              <MarkupPercentageField candidate={candidate} />
            </Col>
          )}
        </Row>
        <Row>
          <Col xs={3}>
            <StaffingFirmField candidate={candidate} />
          </Col>
        </Row>
        {!employer && (
        <Row>
          <Col xs={3}>
            <TimesheetSubmittersField contract={contract} />
          </Col>
        </Row>
        )}
      </FieldSection>
    </>
  );
}

export default ContractInformation;
