import { createPermPlacementScope, createTempPlacementScope } from "../../TempInviteAgency/fieldHelpers";

export const buildInitialValues = ({ agencyRecruiter, currentOrganization }) => {
  const { email, firstName, lastName } = agencyRecruiter.individual.attributes;

  return {
    placementTypes: {
      perm: !currentOrganization.contractActivated,
      temp: false,
    },
    email,
    first_name: firstName,
    last_name: lastName,
    perm: createPermPlacementScope(),
    ...(currentOrganization.contractActivated && {
      temp: createTempPlacementScope(),
    }),
  };
};
