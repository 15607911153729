import { InputAdornment, TextField } from "@material-ui/core";
import { DeleteForever } from "@material-ui/icons";
import { useFormikContext } from "formik";
import { get } from "lodash";
import React, { useCallback, useEffect } from "react";

import { Col, Row } from "react-grid-system";
import NumberFormat from "react-number-format";

import DropdownInput from "../../../../forms/custom/DropdownInput.react";
import { PERCENTAGE_OF_COMPENSATION, PERCENTAGE_RANGE } from "../../../../forms/formik/FormikFeeOptions/fieldHelpers";
import FormikFeeOptions from "../../../../forms/formik/FormikFeeOptions/FormikFeeOptions";

const FEE_TYPE_OPTIONS = [
  { value: PERCENTAGE_RANGE, label: "Range" },
  { value: PERCENTAGE_OF_COMPENSATION, label: "Percentage" },
];

function MarkupFeeOptionRow({
  fieldArrayName = "temp.markupFeeOptions",
  idx = 0,
  arrayHelpers,
  formProps: { errors: formPropErrors, values: formPropValues },
  field: {
    field: { value: fieldValue, onChange, onBlur },
    form: { touched: fieldFormTouched, setFieldValue, values: fieldFormValues },
  },
  fitModal
}) {
  const error = get(formPropErrors, `${fieldArrayName}.${idx}`);
  const touched = get(fieldFormTouched, `${fieldArrayName}.${idx}`);
  const multipleItems = !Boolean(
    get(formPropValues, fieldArrayName).length === 1
  );

  const getFieldErrorMessage = (fieldName) =>
    Boolean(get(touched, fieldName)) && get(error, fieldName);

  const feeType = fieldValue.fee_type;

  const isRange = feeType === PERCENTAGE_RANGE;

  const handleFeeTypeChange = useCallback((feeTypeChange) => {
    if (feeTypeChange === PERCENTAGE_OF_COMPENSATION) {
      setFieldValue(`${fieldArrayName}.${idx}.fee_max`, "");
    }
  }, [setFieldValue, fieldArrayName, idx]);

  return (
    <Row style={{ paddingBottom: 8 }}>
      <Col sm={fitModal ? 2.5 : 2} xs={3}>
        <DropdownInput
          id={`${fieldArrayName}.${idx}.fee_type`}
          label="Markup Fee Type"
          options={FEE_TYPE_OPTIONS}
          value={fieldValue.fee_type}
          error={Boolean(getFieldErrorMessage("fee_type"))}
          helperText={getFieldErrorMessage("fee_type")}
          onChange={(e) => {
            setFieldValue(`${fieldArrayName}.${idx}.fee_type`, e.target.value);
            handleFeeTypeChange(e.target.value);
          }}
          onBlur={onBlur}
          noShrink
          inputProps={{
            "data-cy": `fee-type-${idx}-dropdown-input`,
          }}
        />
      </Col>
      <Col sm={fitModal ? 2.5 : 2} xs={3}>
        <NumberFormat
          id={`${fieldArrayName}.${idx}.fee`}
          value={fieldValue.fee}
          onChange={onChange}
          onBlur={onBlur}
          label={isRange ? "Min Percentage" : "Percentage"}
          error={get(error, "fee") && get(touched, "fee")}
          touched={get(touched, "fee")}
          delayTime={1000}
          customInput={TextField}
          helperText={getFieldErrorMessage("fee")}
          InputProps={
            {
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
              inputProps: {
                "data-cy": `fee-number-format-input-${idx}`,
              },
            }
          }
        />
      </Col>
      {isRange && (
        <Col sm={fitModal ? 2.5 : 2} xs={3}>
          <NumberFormat
            id={`${fieldArrayName}.${idx}.fee_max`}
            value={fieldValue.fee_max}
            onChange={onChange}
            onBlur={onBlur}
            label="Max Percentage"
            error={get(error, "fee_max") && get(touched, "fee_max")}
            touched={get(touched, "fee_max")}
            delayTime={1000}
            customInput={TextField}
            helperText={getFieldErrorMessage("fee_max")}
            InputProps={
              {
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                inputProps: {
                  "data-cy": `fee-max-number-format-input-${idx}`,
                },
              }
            }
          />
        </Col>
      )}
      <Col xs={3}>
        {multipleItems && (
          <TextField
            id={`${fieldArrayName}.${idx}.fee_note`}
            multiline
            error={Boolean(getFieldErrorMessage("fee_note"))}
            helperText={getFieldErrorMessage("fee_note")}
            label={`Fee ${idx + 1} Note`}
            onChange={onChange}
            value={fieldValue.fee_note}
            onBlur={onBlur}
            inputProps={{ "data-cy": `fee-note-${idx}` }}
          />
        )}
      </Col>
      <Col
        xs={1}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
          paddingBottom: 16,
          paddingLeft: 0,
        }}
      >
        {multipleItems && (
          <DeleteForever
            data-cy="delete-fee-option-button"
            onClick={() => {
              arrayHelpers.remove(idx);
              if (get(fieldFormValues, fieldArrayName).length === 2) {
                setFieldValue("multiple_fees", false);
              }
            }}
            style={{
              color: "#FF6D6D",
              cursor: "pointer",
              fontSize: "28px",
              height: "28px",
              width: "28px",
            }}
          />
        )}
      </Col>
    </Row>
  );
}

function MarkupFeeOptions({ fitModal }) {
  const { values, errors, setFieldValue } = useFormikContext();

  const numFeeOptions = get(values, "temp.markupFeeOptions").length;

  useEffect(() => {
    setFieldValue("temp.markupMultipleFees", numFeeOptions > 1);
  }, [numFeeOptions, setFieldValue]);

  return (
    <FormikFeeOptions
      fieldArrayName="temp.markupFeeOptions"
      formProps={{ values, errors }}
      maxOptions={10}
      CustomRowComponent={MarkupFeeOptionRow}
      fitModal={fitModal}
    />
  );
}

export default MarkupFeeOptions;
