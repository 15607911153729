import React from "react";

import PageCardRowHeader from "../../../General/PageCardRowHeader";

import ContractMarkupFees from "./ContractMarkupFees/ContractMarkupFees";
import FieldSectionContainer from "./FieldContainer";
import FeeOptionsField from "./shared/FeeOptionsField";
import FeeTypeField from "./shared/FeeTypeField";
import PositionAssignmentsField from "./shared/PositionAssignmentsField";

function ContractWorkforce({ editFees = false }) {
  return (
    <>
      <PageCardRowHeader title="Contract Workforce" />
      <FieldSectionContainer>
        <p>
          Select conversion fees for contract workers who are made full time.
          You will select the relevant fee at the time of conversion.
        </p>
        <FeeTypeField
          scope="temp"
          label="Select a candidate conversion fee type"
        />
        <FeeOptionsField scope="temp" typeLabel="Conversion to perm rate" />
        <ContractMarkupFees fitModal={editFees} />
        {!editFees && <PositionAssignmentsField scope="temp" />}
      </FieldSectionContainer>
    </>
  );
}

export default ContractWorkforce;
