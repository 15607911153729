import each from "lodash/each";
import forEach from "lodash/forEach";
import isArray from "lodash/isArray";
import queryString from "query-string";
import { useCallback, useMemo } from "react";

function formatArray(array, arrayify) {
  return arrayify ? `[${array.join(",")}]` : array.join(",");
}

// this hook will be better once we upgrade react-router-dom and can make use of its hooks
// rather than reading from args
const useRouteParamFilters = ({
  history, location, arrayify = true, filterKeyPrefix = null
}) => {
  const updateFilters = useCallback(
    (updateKeyValues) => {
      const newFilters = queryString.parse(location.search);

      each(updateKeyValues, (newValue, filterKey) => {
        const key = filterKeyPrefix ? `${filterKeyPrefix}_${filterKey}` : filterKey;

        if (!newValue || (isArray(newValue) && !newValue.length)) {
          delete newFilters[key];
        } else {
          newFilters[key] = isArray(newValue)
            ? formatArray(newValue, arrayify)
            : newValue;
        }
      });

      history.push({
        search: queryString.stringify(newFilters),
      });
    },
    [location.search, history, arrayify, filterKeyPrefix]
  );

  const parsedFilters = useMemo(() => {
    const rawFilters = queryString.parse(location.search);
    const parsedObject = {};

    forEach(rawFilters, (value, key) => {
      const adjustedKey = filterKeyPrefix ? key.replace(`${filterKeyPrefix}_`, '') : key;

      if (arrayify) {
        parsedObject[adjustedKey] =
          value && value[0] === "["
            ? value.slice(1, value.length - 1).split(",")
            : value;
      } else {
        parsedObject[adjustedKey] = value.slice(0, value.length).split(",");
      }
    });

    return parsedObject;
  }, [location.search, arrayify, filterKeyPrefix]);

  return [parsedFilters, updateFilters];
};

export default useRouteParamFilters;
