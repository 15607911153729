import { useFormikContext } from "formik";
import get from "lodash/get";
import React from "react";

import MarkupFeeOptions from "./MarkupFeeOptions";
import MarkupFeesToggle from "./MarkupFeesToggle";

function ContractMarkupFees({ fitModal }) {
  const { values } = useFormikContext();

  const markupFeesEnabled = get(values, "temp.markupFeesEnabled");

  return (
    <>
      <MarkupFeesToggle />
      {markupFeesEnabled && <MarkupFeeOptions fitModal={fitModal} />}
    </>
  );
}

export default ContractMarkupFees;
