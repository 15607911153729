import { SvgIcon } from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import CheckIcon from '@material-ui/icons/Check';
import EditIcon from '@material-ui/icons/Edit';
import SkipBackward from 'mdi-material-ui/SkipBackward';
import TransitTransfer from 'mdi-material-ui/TransitTransfer';
import React from 'react';
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { openModal, openProfileDrawer } from '../../../actions/shared/uiActions';
import { ReactComponent as FileDownload } from '../../../assets/images/file-download.svg';
import { getCandidate } from "../../../selectors/candidateSelectors";
import BackModal from "../../employer/JobCasts/view/Candidates/actions/modals/BackModal";
import ShareModal from '../../employer/JobCasts/view/Candidates/actions/modals/ShareModal';
import TransferModal from "../../employer/JobCasts/view/Candidates/actions/modals/TransferModal";
import { CONTRACT_WORK } from '../../employer/JobCasts/view/Candidates/constants';
import EmployerCandidateDrawer from '../../employer/JobCasts/view/Candidates/view/CandidateDrawer';

import ConvertContractModal from '../../employer/TempJobCasts/view/Candidates/actions/modals/ConvertContract/ConvertContractModal';
import ActionMenu from '../../General/ActionMenu';
import SubmitHoursModal from '../../recruiter/Candidates/actions/modals/SubmitHoursModal';
import RecruiterCandidateDrawer from '../../recruiter/Candidates/view/CandidateDrawer';

import CloseContractModal from './CloseContractModal';
import EditContractModal from './EditContractModal';

const SUBMIT_HOURS = "add_hours";
const CLOSE_CONTRACT = 'end_contract';
const CONVERT_CONTRACT = 'convert_contract';
const EDIT_CONTRACT = 'edit_contract';
const SHARE = "share";
const TRANSFER = "transfer_between_jobs";
const DOWNLOAD_CANDIDATE_PROFILE = "download_candidate_profile";
const BACK = "move_backward";

const getActions = (candidate, { recruiter, employer }) => {
  switch (candidate.attributes.status) {
    case "contract_active":
      return [
        {
          name: SUBMIT_HOURS,
          icon: <AttachMoneyIcon />,
          isVisible: recruiter && candidate.attributes.payPeriodsCount > 0 && !candidate.attributes.contractHasEmployerSubmittedTimesheets,
        },
        {
          name: CONVERT_CONTRACT,
          icon: <CheckIcon />,
          isVisible: employer,
        },
        {
          name: CLOSE_CONTRACT,
          icon: (
            <SvgIcon>
              <path d="M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M9,9H15V15H9" />
            </SvgIcon>
          ),
          style: { color: "#008dae" },
          isVisible: candidate.attributes.contractEndedAt == null,
        },
        {
          name: EDIT_CONTRACT,
          icon: <EditIcon />,
          isVisible: true,
        }
      ];
    case "offer":
      return candidate.attributes.offPlatform ?
        [{
          name: EDIT_CONTRACT,
          icon: <EditIcon />,
          isVisible: true,
        }] : [
          {
            name: SHARE,
            icon: "share-variant",
          },
          {
            name: DOWNLOAD_CANDIDATE_PROFILE,
            icon: <FileDownload />,
          },
          { name: BACK, icon: <SkipBackward /> },
          {
            name: EDIT_CONTRACT,
            icon: <EditIcon />,
            isVisible: true,
          },
        ];
    default:
      return [];
  }
};

const actionhandler = (candidate, dispatch) => (actionName) => {
  switch (actionName) {
    case CLOSE_CONTRACT:
      dispatch(openModal(<CloseContractModal candidate={candidate} />));
      break;
    case SUBMIT_HOURS:
      dispatch(openModal(
        <SubmitHoursModal candidateId={candidate.id} {...{ setSubmitted: () => {} }} />
      ));
      break;
    case EDIT_CONTRACT:
      dispatch(openModal(
        <EditContractModal candidate={candidate} />
      ));
      break;
    case CONVERT_CONTRACT:
      dispatch(openModal(
        <ConvertContractModal candidateId={candidate.id} />
      ));
      break;
    case SHARE:
      dispatch(openModal(
        <ShareModal
          selectedCandidateId={candidate.id}
          jobcastId={candidate.attributes.jobcastId}
        />
      ));
      break;
    case TRANSFER:
      dispatch(openModal(<TransferModal candidate={candidate} />));
      break;
    case DOWNLOAD_CANDIDATE_PROFILE:
      if (candidate.attributes.candidateInfoPdf) {
        fetch(candidate.attributes.candidateInfoPdf)
          .then(resp => resp.blob())
          .then(blob => {
            const url = window.URL.createObjectURL(blob);
            const infoLink = document.createElement('a');
            infoLink.href = url;
            infoLink.download = `${candidate.attributes.firstName}_${candidate.attributes.lastName}_recruitifi_profile.pdf`;
            infoLink.click();
          });
      } else {
        fetch(candidate.attributes.resume)
          .then(resp => resp.blob())
          .then(blob => {
            const url = window.URL.createObjectURL(blob);
            const resumeLink = document.createElement('a');
            resumeLink.href = url;
            resumeLink.download = `${candidate.attributes.firstName}_${candidate.attributes.lastName}_resume.pdf`;
            resumeLink.click();
          });
      }
      break;
    case BACK:
      dispatch(openModal(<BackModal candidate={candidate} />));
      break;
    default:
      break;
  }
};

export const ActionMenuWrapper = withStyles({ menuPaper: { width: 250 } })(
  ({
    recruiterSubmissionId,
    recruiter = false,
    employer = false,
    classes
  }) => {
    const dispatch = useDispatch();
    const candidate = useSelector(
      (state) => getCandidate(state, recruiterSubmissionId),
      shallowEqual
    );

    return (
      <ActionMenu
        actions={getActions(candidate, { recruiter, employer })}
        handleActionSelection={actionhandler(candidate, dispatch)}
        menuClasses={{ paper: classes.menuPaper }}
      />
    );
  }
);

export function RecruiterSubmissionName(
  { employer = false, recruiter = false, recruiterSubmissionId }
) {
  const dispatch = useDispatch();
  const recruiterSubmission = useSelector((state) => (
    getCandidate(state, recruiterSubmissionId)
  ));

  if (!employer && !recruiter) throw new Error("Provide either recruiter or employer as prop");

  function handleClick() {
    const CandidateDrawer = (() => {
      if (employer) return EmployerCandidateDrawer;
      if (recruiter) return RecruiterCandidateDrawer;
      return null;
    })();

    dispatch(openProfileDrawer(
      <CandidateDrawer candidateId={recruiterSubmissionId} navSelection={CONTRACT_WORK} />,
      recruiterSubmission.attributes.jobcastId
    ));
  }

  return (
    <div
      role="button"
      tabIndex={0}
      className="truncate psuedo-link"
      style={{
        color: "#008DAE",
        textDecoration: "underline",
        cursor: "pointer"
      }}
      onClick={handleClick}
      onKeyDown={handleClick}
    >
      {recruiterSubmission.attributes.firstName}
      {" "}
      {recruiterSubmission.attributes.lastName}
    </div>
  );
}
