import every from "lodash/every";
import filter from "lodash/filter";
import find from "lodash/find";
import isArray from "lodash/isArray";
import { createSelector } from "reselect";

import createDeepEqualSelector from "./createDeepEqualSelector";

function selectOpenContracts({ attributes }) {
  const currentDate = new Date();

  return attributes.activatedAt !== null &&
    (attributes.endedAt === null || new Date(attributes.endedAt) >= currentDate);
}

function selectClosedContracts({ attributes }) {
  const currentDate = new Date();

  return attributes.activatedAt !== null &&
    attributes.endedAt !== null &&
    new Date(attributes.endedAt) <= currentDate;
}

function selectPendingContracts({ attributes }) {
  // TODO: possible that endedAt is in the future and we should check for
  // null or if it's in the past
  return attributes.activatedAt === null;
}

function buildFilterFunctions({
  approverId,
  approverIds,
  open,
  closed,
  candidateId,
  contractId,
  pending,
  positionId,
  recruiterId,
  ids
}) {
  const filterFunctions = [];

  if (open) {
    filterFunctions.push(selectOpenContracts);
  }

  if (closed) {
    filterFunctions.push(selectClosedContracts);
  }

  if (pending) {
    filterFunctions.push(selectPendingContracts);
  }

  if (candidateId) {
    filterFunctions.push(
      ({ attributes }) => attributes.recruiterSubmissionId === candidateId
    );
  }

  if (recruiterId) {
    filterFunctions.push(
      ({ attributes }) => attributes.recruiterId === recruiterId
    );
  }

  if (contractId) {
    if (isArray(contractId)) {
      const includedIds = new Set(contractId);

      filterFunctions.push(({ id }) => includedIds.has(id));
    } else {
      filterFunctions.push(({ id }) => id === contractId);
    }
  }

  if (positionId) {
    filterFunctions.push(
      ({ attributes }) => attributes.positionId === positionId
    );
  }

  if (approverId) {
    filterFunctions.push(({ attributes }) =>
      Boolean(find(attributes.primaryApprovers, { id: approverId })));
  }

  if (approverIds && approverIds.length) {
    filterFunctions.push(
      ({ attributes }) => attributes.primaryApprovers.some(({ id }) => approverIds.includes(id))
    );
  }

  if (ids) {
    const includedIds = new Set(ids);

    filterFunctions.push(({ id }) => includedIds.has(id));
  }

  return filterFunctions;
}

export function getContracts({ contracts }) {
  return contracts;
}

export function makeGetContractItems() {
  return createSelector([getContracts], ({ items }) => items);
}

export const makeGetFilteredContracts = () => {
  const getFilters = (_state, filters = {}) => filters;

  const filtersSelector = createDeepEqualSelector(
    [getFilters],
    (filters) => filters
  );

  return createSelector(
    [makeGetContractItems(), filtersSelector],
    (items, filters) => {
      const filterFunctions = [].concat(buildFilterFunctions(filters));

      return filter(items, (item) => (
        every(filterFunctions, (filterFunction) => filterFunction(item))
      ));
    }
  );
};
