import map from "lodash/map";
import orderBy from "lodash/orderBy";
import React, { useMemo } from "react";
import { Col, Row } from "react-grid-system";
import { useSelector } from "react-redux";

import { getCurrentOrganization } from "../../../../../../selectors/organizationSelectors";
import FormContextDropdown from "../../../../../forms/formik/FormContextInputs/FormContextDropdown";
import FormContextTextField from "../../../../../forms/formik/FormContextInputs/FormContextTextField";

import SectionHeader from "../../../../JobCasts/view/Candidates/actions/modals/Hire/SectionHeader";
import FieldSection from "../FieldSection";

import TimesheetApproversField from "./TimesheetApproversField";

function CostCenterField() {
  const costCenters = useSelector((state) => state.costCenters.items);
  const options = useMemo(
    () => orderBy(
      map(costCenters, ({ attributes: { id, name } }) => ({
        label: name,
        value: id,
      })),
      ({ label }) => label.toLowerCase()
    ),
    [costCenters]
  );

  return (
    <FormContextDropdown
      id="costCenterId"
      label="Cost Center"
      options={options}
    />
  );
}

function InvoiceMemoField() {
  return (
    <FormContextTextField id="invoiceMemo" label="Invoice Memo (optional)" />
  );
}

function BillingInformation() {
  const currentOrganization = useSelector(getCurrentOrganization);

  const sectionLabel = currentOrganization.ukgWorkforceIntegrationEnabled ? "Contract Managers" : "Timesheet Approvers";
  const sectionDescription = currentOrganization.ukgWorkforceIntegrationEnabled ?
    "The following people may receive reminder emails when this contract is close to ending and will be responsible for converting the worker through RecruitiFi if they are converted to full-time." :
    "The following people may receive emails to approve timesheets for this worker and can access the hours worked.";

  return (
    <>
      <SectionHeader title="Billing Information" />
      <FieldSection>
        <Row>
          <Col xs={12} md={4}>
            <CostCenterField />
          </Col>
          <Col xs={12} md={4}>
            <InvoiceMemoField />
          </Col>
        </Row>
      </FieldSection>
      <SectionHeader title={sectionLabel} />
      <FieldSection>
        <Row>
          <Col xs={12}>
            <p style={{ marginTop: 0 }}>
              {sectionDescription}
            </p>
            <TimesheetApproversField />
          </Col>
        </Row>
      </FieldSection>
    </>
  );
}

export default BillingInformation;
