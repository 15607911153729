import includes from "lodash/includes";
import map from "lodash/map";
import round from "lodash/round";

import {
  PERCENTAGE_OF_COMPENSATION,
  PERCENTAGE_RANGE,
} from "../../../../forms/formik/FormikFeeOptions/fieldHelpers";
import { createBlankFeeOption } from "../../TempInviteAgency/fieldHelpers";

export const buildInitialValues = ({ agency, currentOrganization }) => {
  const {
    id,
    employerAbsorptionRate,
    tempEnabled,
    permEnabled,
    optimizedFee,
    tempOptimizedFee,
    feeOptions,
    tempFeeOptions,
    tempMarkupEnabled,
    tempMarkupFeeOptions,
  } = agency.preferredLink.attributes;

  const mapFeeOptions = (feeOptions) => {
    if (feeOptions.length === 0) {
      return [createBlankFeeOption()];
    }

    return map(feeOptions, (feeOption) => ({
      fee:
        includes([PERCENTAGE_OF_COMPENSATION, PERCENTAGE_RANGE], feeOption.feeType)
          ? round(feeOption.feePercentage * 100, 2).toString()
          : (feeOption.feeCents / 100).toString(),
      fee_note: feeOption.note,
      id: feeOption.id,
      fee_type: feeOption.feeType,
      currency: feeOption.currency,
      fee_max: feeOption.feePercentageMax ?
        round(feeOption.feePercentageMax * 100, 2).toString() :
        null,
    }));
  };

  const determineFeeType = (scope) => {
    if (scope === "perm" && optimizedFee) {
      return "optimized";
    }
    if (scope === "temp" && (tempOptimizedFee || !tempEnabled)) {
      return "optimized";
    }
    if (currentOrganization.hasStandardizedPreferredFees) {
      return "standardized";
    }

    return "custom";
  };

  const feeType = determineFeeType("perm");
  const tempFeeType = determineFeeType("temp");

  return {
    id,
    placementTypes: {
      perm: permEnabled,
      temp: tempEnabled || false,
    },
    perm: {
      feeType,
      feeOptions: mapFeeOptions(feeOptions),
      multipleFees: feeType === "custom" && feeOptions.length > 1,
    },
    temp: {
      feeType: tempFeeType,
      feeOptions: mapFeeOptions(tempFeeOptions),
      multipleFees: tempFeeType === "custom" && tempFeeOptions.length > 1,
      markupFeesEnabled: tempMarkupEnabled,
      markupFeeOptions: mapFeeOptions(tempMarkupFeeOptions),
      markupMultipleFees:
        tempMarkupEnabled && tempMarkupFeeOptions.length > 1,
    },
    employerAbsorptionRate: parseFloat(employerAbsorptionRate),
  };
};
