import { useFormikContext } from "formik";

import { chain } from "lodash";
import filter from "lodash/filter";
import includes from "lodash/includes";
import map from "lodash/map";
import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";

import { makeGetTeammates } from "../../../../../../selectors/employer/teammateSelectors";
import { getCurrentOrganization } from "../../../../../../selectors/organizationSelectors";
import DropdownSelect from "../../../../../forms/custom/DropdownSelect";
import { ACTIVE } from "../../../../../shared/Team/constants";
import { TEAM_MANAGER, FINANCE_MANAGER, TIMESHEET_APPROVER } from '../../../../Team/constants';

const roles = [TEAM_MANAGER, FINANCE_MANAGER, TIMESHEET_APPROVER];

function TimesheetApproversField({ openUpwards = true }) {
  const {
    values, setFieldValue, touched, errors, handleBlur
  } = useFormikContext();

  const teammates = useSelector(makeGetTeammates(ACTIVE, false));
  const currentOrganization = useSelector(getCurrentOrganization);

  const options = useMemo(() => (chain(teammates)
    .filter(({ attributes }) => (
      attributes.roles.some(v => roles.includes(v))
    ))
    .map(({ id, attributes }) => ({
      value: id,
      label: attributes.name,
    }))
    .value()
  ), [teammates]);

  const value = useMemo(
    () => filter(options, (option) => includes(values.timesheetApproverIds, option.value)),
    [values.timesheetApproverIds, options]
  );

  const handleChange = useCallback(
    (newValue) => {
      const newValues = map(newValue, "value");

      setFieldValue("timesheetApproverIds", newValues);
    },
    [setFieldValue]
  );

  const errorMessage =
    touched.timesheetApproverIds && errors.timesheetApproverIds;

  return (
    <DropdownSelect
      {...{ options, value }}
      id="timesheetApproverIds"
      label={currentOrganization.ukgWorkforceIntegrationEnabled ? "Primary Managers" : "Primary Approvers"}
      isMulti
      isClearable
      openUpwards={openUpwards}
      onChange={handleChange}
      error={Boolean(errorMessage)}
      helperText={errorMessage}
      onBlur={handleBlur}
      overrideStyles={{ menuList: { maxHeight: 250 } }}
    />
  );
}

export default TimesheetApproversField;
