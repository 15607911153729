import every from "lodash/every";
import filter from "lodash/filter";
import find from "lodash/find";
import includes from "lodash/includes";
import isArray from "lodash/isArray";
import map from "lodash/map";
import { createSelector } from "reselect";

import createDeepEqualSelector from "../createDeepEqualSelector";

export const getPlacements = ({ finance: { placements } }) => placements;

export const getPreferredLinks = (state) => state.preferredLinks;

export const getPaymentNotifications = ({
  finance: { paymentNotifications },
}) => paymentNotifications;

export const getPaymentMethod = ({ finance: { paymentMethod } }) =>
  paymentMethod;

export const getStripeAccount = ({ finance: { stripeAccount } }) => stripeAccount;

export const makeGetPaymentNotificationItems = () =>
  createSelector([getPaymentNotifications], ({ items }) => items);

export const makeGetPaymentNotificationsLoaded = () =>
  createSelector([getPaymentNotifications], ({ loaded }) => loaded);

export const makeGetPaymentNotificationItem = () =>
  createSelector(
    [makeGetPaymentNotificationItems(), (_, id) => id],
    (paymentNotifications, id) =>
      find(paymentNotifications, ({ attributes: { associatedPlacementIds } }) =>
        includes(associatedPlacementIds, id))
  );

export const makeGetPlacementsLoaded = () =>
  createSelector([getPlacements], ({ loaded }) => loaded);

export const makeGetPlacementItems = () =>
  createSelector([getPlacements], (placements) => placements.items);

function buildPreferredLinkFilterFunctions({
  perm,
  temp,
  optimizedFee,
  tempOptimizedFee,
  tempMarkupEnabled,
}) {
  const filterFunctions = [];

  if (perm) {
    filterFunctions.push(({ attributes }) => attributes.permEnabled);
  }

  if (temp) {
    filterFunctions.push(({ attributes }) => attributes.tempEnabled);
  }

  if (typeof optimizedFee === "boolean") {
    filterFunctions.push(
      ({ attributes }) => attributes.optimizedFee === optimizedFee
    );
  }

  if (typeof tempOptimizedFee === "boolean") {
    filterFunctions.push(
      ({ attributes }) => attributes.tempOptimizedFee === tempOptimizedFee
    );
  }

  if (typeof tempMarkupEnabled === "boolean") {
    filterFunctions.push(
      ({ attributes }) => attributes.tempMarkupEnabled === tempMarkupEnabled
    );
  }

  return filterFunctions;
}

export const makeGetPreferredLinkItems = () => {
  const getFilters = (_state, filters = {}) => filters;

  const filtersSelector = createDeepEqualSelector(
    [getFilters],
    (filters) => filters
  );

  return createSelector(
    [getPreferredLinks, filtersSelector],
    (preferredLinks, filters) => {
      const filterFunctions = buildPreferredLinkFilterFunctions(filters);

      const filteredItems = filter(preferredLinks.items, (preferredLink) =>
        every(filterFunctions, (filterFunction) =>
          filterFunction(preferredLink)));

      return map(filteredItems, (preferredLink) => preferredLink.attributes);
    }
  );
};

export const makeGetPlacementItem = () =>
  createSelector(
    [makeGetPlacementItems(), (_, id) => id],
    (placementItems, id) => placementItems[id]
  );

// FINALIZED ('completed') payout status types
export const PROCESSING = "processing";
export const PROCESSED = "processed";
export const FAILED = "failed";
// PENDING ('upcoming') payout status types
export const SCHEDULED = "scheduled";
export const EXPECTED = "expected";
export const PENDING = "pending";
export const REPORTED_FAILURE = "reported failure";

export const makeGetFilteredPlacements = () => {
  const getFilters = (_state, filters = {}) => filters;

  const filtersSelector = createDeepEqualSelector(
    [getFilters],
    (filters) => filters
  );

  const buildFilterFunctions = ({ payoutStatus }) => {
    const filterFunctions = [];

    if (payoutStatus) {
      filterFunctions.push(({ attributes }) =>
        isArray(payoutStatus)
          ? includes(payoutStatus, attributes.payoutStatus)
          : attributes.payoutStatus === payoutStatus);
    }

    return filterFunctions;
  };

  return createSelector(
    [makeGetPlacementItems(), filtersSelector],
    (items, filters) => {
      const filterFunctions = buildFilterFunctions(filters);

      return filter(items, (item) =>
        every(filterFunctions, (filterFunction) => filterFunction(item)));
    }
  );
};
